import React from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { Icons } from "@/components/ui/icons";
import Link from "next/link";

const Menu: React.FC = () => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="icon">
          <Icons.Menu
            className="rotate-0 scale-100 transition-all hover:text-kick-red"
            size={24}
          />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[140px] h-[155px]">
        <DropdownMenuItem
          className="text-md/80 cursor-pointer hover:text-white dark:hover:text-kick-red"
          asChild
        >
          <Link href="/projects">Projects</Link>
        </DropdownMenuItem>
        <DropdownMenuItem
          className="text-md/80 cursor-pointer hover:text-kick-red/90 dark:hover:text-kick-red"
          asChild
        >
          <Link href="/blog">Blog</Link>
        </DropdownMenuItem>
        <DropdownMenuItem
          className="text-md/80 cursor-pointer hover:text-kick-red/90 dark:hover:text-kick-red"
          asChild
        >
          <Link href="/about">About</Link>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default Menu;
