"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";
import { Heading } from "@/components/typography/heading";

const links = [
  { name: "Projects", href: "/projects" },
  { name: "Blog", href: "/blog" },
  { name: "About", href: "/about" },
];

const Navbar: React.FC = () => {
  const pathname = usePathname();

  return (
    <div className="flex flex-row justify-between space-x-10">
      {links.map((link, i) => (
        <Link key={i} href={link.href}>
          <Heading
            className="hover:text-kick-red/90 dark:hover:text-kick-red/95 transition duration-150 ease-in-out cursor-pointer"
            size="md"
            active={pathname === link.href ? true : false}
          >
            {link.name}
          </Heading>
        </Link>
      ))}
    </div>
  );
};

export default Navbar;
