// Basic
export const firstname = "Sai Darahas";
export const lastname = "Akkineni";
export const tagline = "Software engineer";
export const description = `${tagline} with passion for machine learning, computer vision, and perception in robotics.`;

// Social media
export const twitter = "_SaiDarahas";
export const github = "saihas";
export const linkedin = "saidarahasakkineni";

// Contact
export const email = "akkinenisaidarahas@gmail.com";
export const origin =
  process.env.NODE_ENV === "production"
    ? "https://saidarahas.com"
    : "http://localhost:3000";
