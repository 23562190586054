import { HTMLAttributes, forwardRef } from "react";
import { VariantProps, cva } from "class-variance-authority";
import { cn } from "@/lib/utils";

const headingVariants = cva("leading-tight", {
  variants: {
    size: {
      default: "text-base lg:text-xl font-semibold tracking-tight",
      sm: "text-base lg:text-lg font-semibold tracking-tight",
      md: "text-lg lg:text-xl font-semibold tracking-tight",
      lg: "text-xl lg:text-2xl font-bold tracking-tight",
      xl: "text-3xl/tight lg:text-4xl/tight font-bold tracking-tight",
      xxl: "text-4xl/tight md:text-5xl/tight font-extrabold tracking-tighter",
    },
    active: {
      false: "text-slate-800 dark:text-white",
      true: "text-kick-red font-bold",
    },
  },
  defaultVariants: {
    size: "default",
    active: false,
  },
});

interface HeadingProps
  extends HTMLAttributes<HTMLHeadingElement>,
    VariantProps<typeof headingVariants> {}

const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ className, size, active, children, ...props }, ref) => {
    return (
      <h1
        ref={ref}
        {...props}
        className={cn(headingVariants({ size, active, className }))}
      >
        {children}
      </h1>
    );
  },
);

Heading.displayName = "Heading";

export { headingVariants, Heading };
