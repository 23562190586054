import {
  Menu,
  ArrowUp,
  Box,
  FolderMinus,
  FolderPlus,
  File,
  Layout,
  ChevronRight,
  Info,
} from "lucide-react";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";

export const Icons = {
  Menu,
  ArrowUp,
  faGithub,
  faLinkedin,
  Box,
  FolderMinus,
  FolderPlus,
  File,
  Layout,
  ChevronRight,
  Info,
};

export default Icons;
