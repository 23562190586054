"use client";

import React, { useEffect, useState } from "react";
import Link from "next/link";
import Menu from "@/components/navigation/menu";
import Navbar from "@/components/navigation/navbar";
import { Heading } from "@/components/typography/heading";
import { firstname, lastname } from "@/lib/constants";

interface HeaderProps extends React.HTMLAttributes<HTMLHeadElement> {
  // className?: string;
}

const mobileMediaQuery = "(max-width: 750px)";

const Header: React.FC<HeaderProps> = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const query = window.matchMedia(mobileMediaQuery);

    if (query.matches) {
      setIsMobile(true);
    }

    function handleQueryChange(queryEvent: MediaQueryListEvent) {
      /* The matches property will be true if the window width is below the mobile size. */
      setIsMobile(queryEvent.matches);
    }

    query.addEventListener("change", handleQueryChange);

    return () => {
      query.removeEventListener("change", handleQueryChange);
    };
  }, []);

  return (
    <header className="sticky flex items-center justify-between z-50 w-full py-3 md:py-6 top-0 backdrop-blur-sm bg-white dark:bg-black border-b  shadow-md">
      <div className="container flex justify-between items-center lg:max-w-7xl mx-auto">
        <div className="flex-1 hover:text-gray-700 transition duration-150 ease-in-out">
          <Link href="/">
            <Heading size="lg">
              <strong className="text-slate-800 dark:text-white">
                {firstname}
              </strong>{" "}
              <span className="text-kick-red">{lastname}</span>
            </Heading>
          </Link>
        </div>
        {isMobile ? <Menu /> : <Navbar />}
      </div>
    </header>
  );
};

export default Header;
